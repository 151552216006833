import Vue from 'vue'

export function setLayoutFooter({commit},footername){
    commit('setLayoutFooter',footername)
}

export function setLayoutNav({commit}, navname){
    commit('setLayoutNav', navname)
}


//////////////////// SERVICIOS ////////////////

export async function getMarcas({ state },) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/corporativos/get_politicas',
    })
        .then((response) => {
            state.preguntasFrecuentes = response.data[6].items
        })
}

export async function getComercios({ state },) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/corporativos/layout/get_seccion_aliados',
    })
        .then((response) => {
            state.comerciosAliados = response.data
        })
}

export async function getComerciosSoli({ state },) {
    await Vue.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/cupopersonal/preaprobado/obtener_comercios',
    })
        .then((response) => {
            state.comerciosSoli = response.data.stores
        })
}

export async function getInfoCampaign({ state }, { idCampaign }) {
    await Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `/corporativos/layout/obtener_campania/${idCampaign}`,
    })
        .then((response) => {
            if (response.data) {
                state.infoCampaign = response.data
            }
        })
}
