<template>
  <div>
    <component :is="layoutPrincipal.navBar"></component>
    <main style="min-height: 80dvh;">
      <router-view/>
    </main>
    <component :is="layoutPrincipal.footer"></component>
  </div>
</template>
<script>
import NavPrincipal from '@/components/menu/NavPrincipal.vue'
import NavBar from '@/components/menu/NavBar.vue'
import NewFooter from '@/components/footers/NewFooter.vue'
import FooterBasic from '@/components/footers/FooterBasic.vue'
import { mapState } from 'vuex'
export default {
  components: {
    NavPrincipal,
    FooterBasic,
    NewFooter,
    NavBar,
  },
  computed: {
    ...mapState('globalState',['layoutPrincipal'])
  }
}
</script>
<style lang="scss">
  
</style>