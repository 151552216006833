import Vue from 'vue'
import Vuex from 'vuex'

import cupo from '@/modules/cupo'
import globalState from '@/modules/globalState'
import pqrQuac from '@/modules/pqrQuac'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    departamentos: [],
  },
  mutations: {
  },
  actions: {
    getDepartamentos({state}) {
      Vue.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/departamentos/get_ciudades',
    })
        .then((response) => {
          state.departamentos = response.data.departamentos;
        })
    }
  },
  modules: {
    cupo,
    globalState,
    pqrQuac,
  }
})
