<template>
    <b-modal id="modalcupo" v-model="showModal" centered hide-footer hide-header>
        <div class="container-modal-soli-cupo" v-if="stepSoli == 0">
            <p class="title-modal-soli-cupo">Compra <span>hoy y <br> paga</span> después</p>
            <p class="text-center">Puedes utilizar el cupo personal para pagar tus compras en nuestras tiendas físicas y  online. Accede a las marcas más exclusivas en moda, zapatos y accesorios. <b>¡Solicita tu cupo ya!</b></p>
            <carousel class="col-12 mb-4 mx-auto px-0 position-relative owl-carousel-container"
                :dots="false" 
                :lazyLoad="true" 
                :nav="false"
                :key="updateKeyCarousel"
                :responsive="{ 0:{items:1, nav:false} }">
                <template slot="prev"><span class="prev nav-button"> <img style="transform: rotate(180deg)" src="@/assets/icons/arrow-btn-cupo.svg"> </span></template>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">Tu cupo al <br> instante</p>
                        <p class="description-step-carousel-soli-cupo">Solicita tu cupo online y recibe aprobación inmediata.</p>
                        <span style="color: #9DA9B9; font-size: 12px">(Sujeta a verificación de referencias)</span>
                    </div>
                </div>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">Flexibilidad en <br> pagos</p>
                        <p class="description-step-carousel-soli-cupo">Pagos quincenales y asequibles.</p>
                    </div>
                </div>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">Cuota de <br> manejo gratis</p>
                        <p class="description-step-carousel-soli-cupo">No te cobramos cuota de manejo.</p>
                    </div>
                </div>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">No tienes que <br> dar cuota inicial</p>
                        <p class="description-step-carousel-soli-cupo">Haz tu pedido y empieza a pagarlo en 15 días.</p>
                    </div>
                </div>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">Accede a las <br> marcas más exclusivas</p>
                        <p class="description-step-carousel-soli-cupo">Más de 30 marcas exclusivas disponibles.</p>
                    </div>
                </div>
                <div class="d-flex h-100 px-2">
                    <div class="container-steps-carousel-soli-cupo">
                        <p class="title-step-carousel-soli-cupo">Proceso <br> 100% Digital</p>
                        <p class="description-step-carousel-soli-cupo">¡Sin filas! Haz tus compras y pagos online.</p>
                    </div>
                </div>
                <template slot="next"><span class="next nav-button">  <img src="@/assets/icons/arrow-btn-cupo.svg">  </span></template>
            </carousel>
            <div class="mb-3">
                <button class="btn-soli-cupo w-100" @click="stepSoli += 1">Solicitar cupo personal</button>
            </div>
            <p class="mb-0 text-center" style="line-height: .9"><span style="color: #9DA9B9; font-size: 11px;" class="text-center">*Válido únicamente en Colombia. La aprobación inmediata esta sujeta a la respuesta oportuna del cliente y sus referencias personales. Es requisito ser colombiano, con línea nacional y correo electrónico. </span></p>
        </div>
        <div v-if="stepSoli == 1" class="container-modal-soli-cupo">
            <span v-if="stepForm != 3" class="go-back-cupo-modal" @click="stepForm == 1 ? stepSoli -= 1 : stepForm = 1"><img src="@/assets/icons/flecha-boton.svg" style="width: 30px;"> Volver</span>
            <div v-if="stepForm">
                <div class="px-2" v-if="stepForm == 1">
                    <p class="text-center mt-3 px-2">Vas iniciar un proceso de solicitud, es necesario que ingreses los siguientes datos.</p>
                    <div class="col-12 position-relative pb-1">
                        <input class="input-quac mb-4" @blur="validateBlur('name')" @focus="errorNombre = false" placeholder="Nombres" type="given-name" autocomplete="given-name" name="nombres" v-model="nombres">
                        <span v-if="errorNombre" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="nombres">Nombre</span>
                    </div>
                    <div class="col-12 position-relative pb-1">
                        <input class="input-quac mb-4" @blur="validateBlur('lastName')" @focus="errorApellidos = false" placeholder="Apellidos" type="family-name" autocomplete="family-name" name="apellidos" v-model="apellidos">
                        <span v-if="errorApellidos" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="apellidos">Apellidos</span>
                    </div>
                    <div class="col-12 position-relative pb-1">
                        <input class="input-quac mb-4" @blur="validateBlur('cc')" @focus="errorCC = false" placeholder="Cédula" type="number" name="cc" v-model="cc">
                        <span v-if="errorCC" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="cc">Cédula</span>
                    </div>
                    <div class="col-12 position-relative pb-1">
                        <input class="input-quac w-100 mb-4" :max="maxDate" @blur="validateBlur('date')" @focus="errorDate = false" type="date" name="fecha" v-model="date">
                        <span v-if="errorDate" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input">Fecha de expedición</span>
                    </div>
                    <div class="text-center mb-3" v-if="msgError">
                        <span style="font-size: 14px; color: red;">{{msgError}}</span>
                    </div>
                    <div class="mb-3">
                        <button class="btn-soli-cupo w-100" @click="sendSolicitud()">Continuar</button>
                    </div>
                </div>
                <div v-if="stepForm == 2">
                    <p class="text-center px-2">Agregas unos datos más, esto servirá para ponernos en contacto contigo.</p>
                    <div class="group-input-quac pb-1">
                        <div class="star-group-input-quac">
                            <img src="@/assets/icons/Icon-simple-whatsapp.svg">
                        </div>
                        <input class="input-quac mb-4" @blur="validateBlur('tel')" @focus="errorCelular = false" placeholder="Celular" type="tel" autocomplete="tel" name="tel" v-model="celular">
                        <span class="label-input" v-if="celular">Celular</span>
                        <span v-if="errorCelular" class="error-inputs">Este campo es obligatorio</span>
                    </div>
                    <div class="col-12 position-relative pb-1">
                        <input class="input-quac mb-4" @blur="validateBlur('correo')" @focus="errorCorreo = false" placeholder="Correo electrónico" type="email" autocomplete="email" name="email" v-model="correo">
                        <span v-if="errorCorreo" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="correo">Correo electrónico</span>
                    </div>
                    <div class="col-12 position-relative pb-1">
                        <select class="select-quac w-100 mb-4" @blur="validateBlur('dep')" @focus="campoDepartamentoRequerido = false" v-model="selectedDep" name="selectedDep" id="selectedDep">
                            <option :value="null">Departamento</option>
                            <option :key="index" v-for="(item, index) in departamentos" :value="item.codigo"> {{ item.descripcion.toLowerCase().replace(/^\w/, (d) => d.toUpperCase()) }} </option>
                        </select>
                        <span v-if="campoDepartamentoRequerido" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="selectedDep">Departamento</span>
                    </div>
                    <div class="col-12 position-relative pb-1 mb-4">
                        <select class="select-quac w-100 mb-4" @blur="validateBlur('ciu')" @focus="campoCiudadRequerido = false" v-model="selectedCity" name="selectedDep" id="selectedDep">
                            <option :value="null">Ciudad</option>
                            <option :key="index" v-for="(item, index) in municipiosFiltered" :value="item.codigo"> {{ item.descripcion.toLowerCase().replace(/^\w/, (d) => d.toUpperCase()) }} </option>
                        </select>
                        <span v-if="campoCiudadRequerido" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="selectedCity">Ciudad</span>
                    </div>
                    <div class="mb-4 d-flex align-items-center justify-content-center">
                        <label class="d-flex my-3 mx-0 ms-md-1 me-3">
                            <input class="tyc-checkbox" type="checkbox" checkmark="checkmark" id="acept-t-y-c" v-model="status"/>
                            <span class="checkmark"></span>
                        </label>
                        <label for="acept-t-y-c" class="mb-0 text-start" style="font-size: 1rem">
                            Acepto la
                            <router-link style="font-size: 1rem; color: #654062;" target="_blank" :to="{ name: 'DataPolice' }">consulta, reporte y tratamiento de datos.</router-link>
                        </label>
                    </div>
                    <div class="text-center mb-3" v-if="msgError">
                        <span style="font-size: 14px; color: red;">{{msgError}}</span>
                    </div>
                    <div class="mb-3">
                        <button class="btn-soli-cupo w-100" @click="status ? sendSolicitud() : msgError = 'Acepta los terminos para poder continuar.'">Continuar</button>
                    </div>
                </div>
                <div v-if="stepForm == 3" style="min-height: 460px;" class="d-flex flex-wrap justify-content-center align-content-center">
                    <img src="@/assets/icons/loader-modal-cupo.svg" class="loader-modal-cupo">
                    <p class="text-center w-100">Estamos verificando tus datos, esto <br> puede tardar unos minutos, no <br> cierres o recargues esta página</p>
                </div>
                <div v-if="stepForm == 4" style="min-height: 460px;" class="d-flex flex-wrap justify-content-center">
                    <div class="text-center mt-auto">
                        <p class="bold">Tu solicitud se ha enviado</p>
                        <p class="text-center w-100">Revisaremos tus datos, espera nuestra llamada de comprobación de información y muy pronto podrás estrenar lo último de la moda.</p>
                    </div>
                    <div class="w-100 mt-auto">
                        <button class="btn-soli-cupo w-100 mb-0" @click="showModal = false;">Volver a la tienda</button>
                    </div>
                </div>
                <div class="text-center" v-if="stepForm <= 3">
                    <img src="@/assets/icons/check-cupo-modal.svg" class="pr-2">
                    <span style="font-size: 11px; color: #9DA9B9">Datos encriptados y protegidos</span>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
    /* eslint-disable */
    import carousel from 'v-owl-carousel'
    import { mapState, mapActions } from 'vuex'
    import { BButtonToolbar, BInputGroup, BFormInput, BButtonGroup, BFormCheckbox, VBTooltipPlugin } from 'bootstrap-vue'
    export default {
        components: { carousel, BFormCheckbox, BInputGroup, BFormInput, BButtonGroup, BButtonToolbar, VBTooltipPlugin},
        data() {
            return {
                stepSoli: 0,
                stepForm: 1,
                showModal: false,
                nombres: '',
                errorNombre: false,
                apellidos: '',
                errorApellidos: false,
                cc: '',
                errorCC: false,
                date: null,
                errorDate: false,
                errorCiuedad: false,
                correo: '',
                errorCorreo: false,
                celular: '',
                errorCelular: false,
                loading: false,
                msgError: '',
                msgSuccess: '',
                selectedDep: null,
                campoDepartamentoRequerido: false,
                selectedCity: null,
                campoCiudadRequerido: false,
                status: false,
                updateKeyCarousel: false,
            }
        },
        computed: {
            ...mapState(['departamentos']),
            validateNombres(){
                return this.nombres.length > 2;
            },
            validateApellidos(){
                return this.apellidos.length > 2;
            },
            validateCC(){
                return this.cc.length > 4 && this.cc.length < 11;
            },
            validateDate(){
                if(this.date != null){
                    let dateSplit = this.date.split('-')
                    let dateNow = new Date()
                    if(dateNow.getFullYear() > parseInt(dateSplit[0])){
                        return true
                    }else if(dateNow.getFullYear() == parseInt(dateSplit[0])){
                        if((dateNow.getMonth() + 1) > parseInt(dateSplit[1])){
                            return true
                        }else if((dateNow.getMonth() + 1) == dateSplit[1]){
                            if(dateNow.getDate() > parseInt(dateSplit[2])){
                                return true
                            }else if(dateNow.getDate() == dateSplit[2]){
                                return true
                            }
                        }
                    }
                }
                return false
            },
            validateCiuedad(){
                return this.selectedCity;
            },
            validateCorreo(){

                let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(this.correo);
            },
            validateCelular(){
                const re = /^3[0-9]{9}/;
                return re.test(this.celular) && this.celular.length < 11;
            },
            municipiosFiltered: function(){
                var temp = []
                if (this.selectedDep && this.departamentos.length > 0) {
                    temp = this.departamentos.filter(municipio => municipio.codigo == this.selectedDep)[0].ciudades
                    if(temp.find(element => element.codigo == this.selectedCity) == undefined) {
                        this.selectedCity = null;
                    }
                }else{{
                    this.selectedCity = null;
                }}
                return temp
            },
            maxDate(){
                let date = new Date();
                let year = date.getFullYear();
                let month = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
                let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
                return   `${year}-${month}-${day}`
            },
        },
        watch: {
            async showModal(){
                if(!this.showModal){
                    this.nombre = '';
                    this.apellidos = '';
                    this.cc = '';
                    this.date = null;
                    this.celular = '';
                    this.correo = '';
                    this.msgError = '';
                    this.msgSuccess = '';
                    this.errorNombre = false;
                    this.errorApellidos = false;
                    this.errorCC = false;
                    this.errorDate = false;
                    this.errorCiuedad = false;
                    this.errorCorreo = false;
                    this.errorCelular = false;
                    this.campoDepartamentoRequerido = false;
                    this.campoCiudadRequerido = false;
                    this.stepForm = 1;
                    this.stepSoli = 0;
                    this.updateKeyCarousel = false;
                }else{
                    setTimeout(() => {
                        this.updateKeyCarousel = true;
                    }, 10);
                }
            },
            cc(){
                if(this.cc < 0){
                    this.cc = null
                }
            },
            stepForm(){
                if(this.stepForm == 4){
                    this.$analytics.fbq.event('CompleteRegistration');
                    this.$gtag.event('Lead');
                    this.$gtag.event('Solicitud cupo', {
                        event: 'Solictud cupo',
                        event_category: 'formulario',
                    });
                }
            },
            status(){
                if(this.status) {
                    this.msgError = '';
                }
            },
            selectedDep() {
                this.selectedCity = null;
            },
        },
        async mounted() {
            this.date = new Date().dateNow;
            // this.getDepartamentos();
        },
        methods: {
            ...mapActions(['getDepartamentos']),
            validateBlur(input){
                if(input == 'name'){
                    this.errorNombre = !this.validateNombres
                }else if(input == 'lastName'){
                    this.errorApellidos = !this.validateApellidos
                }else if(input == 'cc'){
                    this.errorCC = !this.validateCC
                }else if(input == 'date'){
                    this.errorDate = !this.validateDate
                }else if(input == 'tel'){
                    this.errorCelular = !this.validateCelular
                }else if(input == 'correo'){
                    this.errorCorreo = !this.validateCorreo
                }else if(input == 'dep'){
                    this.campoDepartamentoRequerido = !this.selectedDep
                }else if(input == 'ciu'){
                    this.campoCiudadRequerido = !this.selectedCity
                }else{
                    return false;
                }
            },
            async sendSolicitud(){
                if((this.stepForm == 1 && this.validateNombres && this.validateApellidos && this.validateCC && this.validateDate) || this.stepForm == 2 && this.validateCelular && this.validateCorreo && this.validateCiuedad || this.stepForm > 2){
                    let tempStpe = this.stepForm;
                    this.msgError = '';
                    this.stepForm = 3;
                    let data = new FormData();
                    data.append('nombres', this.nombres);
                    data.append('apellidos', this.apellidos);
                    data.append('documento', this.cc);
                    data.append('fecha_expedicion', this.date);
                    if(tempStpe != 1){
                        data.append('step', 2);
                        data.append('celular1', this.celular);
                        data.append('correo1', this.correo);
                        data.append('documento', this.cc);
                        data.append('ciudad', this.selectedCity);
                        data.append('departamento', this.selectedDep);
                    }else{
                        data.append('step', 1);
                    }
                    await this.axios({
                        method: 'POST',
                        headers: { 'content-type': 'application/x-www-form-urlencoded'},
                        url: '/cupopersonal/registrarSolicitudExterna',
                        data: data
                    })
                    .then(() => {
                        if(tempStpe == 2){
                            tempStpe = 4;
                        }else{
                            tempStpe += 1;
                        }
                    }).catch((e)=>{
                        let message = ""
                        for (const property in e.response.data.errors) {
                            message = message + e.response.data.errors[property] + " "
                        }
                        this.msgError = message;
                        this.stepForm = tempStpe;
                    })
                    .finally(()=>{
                        this.stepForm = tempStpe;
                    })
                }else{
                    if(this.stepForm == 1){
                        this.errorNombre = !this.validateNombres;
                        this.errorApellidos = !this.validateApellidos;
                        this.errorCC = !this.validateCC;
                        this.errorDate = !this.validateDate
                    }else{
                        this.errorNombre = !this.validateNombres;
                        this.errorApellidos = !this.validateApellidos;
                        this.errorCC = !this.validateCC;
                        this.errorCiuedad = !this.validateCiuedad;
                        this.errorCorreo = !this.validateCorreo;
                        this.errorCelular = !this.validateCelular;
                        this.campoDepartamentoRequerido = !this.selectedDep;
                        this.campoCiudadRequerido = !this.validateCiuedad;
                    }
                }
            }
        },
    }
</script>
<style lang="scss">
    #modalcupo{
        .modal-dialog{
            max-width: 400px;
        }
        .modal-content {
            background-color: #FFFFFF;
            border-radius: 10px;
        }
        .modal-body{
            padding: 2rem !important;
        }
        .container-modal-soli-cupo{
            .title-modal-soli-cupo{
            color: #EF6D58;
            font-size: 28px;
            font-weight: bold;
            line-height: .8;
            text-align: center;
            span{
                color: #28264A;
            }
            }
        }
        .owl-item{
            margin-right: auto;
            margin-left: auto;
            @media (max-width: 420px) {
            max-width: auto;
            min-width: auto;
            }
        }
        .owl-stage{
            padding: 0rem !important;
            display: flex;
            width: 100%;
            align-items: stretch;
        }
        .owl-carousel-container .nav-button.next{
            right: -30px !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        .owl-carousel-container .nav-button.prev{
            left: -30px !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        .container-steps-carousel-soli-cupo{
            border: 1px solid #525FFB;
            border-radius: 10px;
            width: 100%;
            padding: 1rem 2rem;
            text-align: center;
            .title-step-carousel-soli-cupo{
            font-size: 26px;
            font-weight: bold;
            line-height: 1;
            }
            .description-step-carousel-soli-cupo{
            margin-bottom: 0;
            }
        }
        .btn-soli-cupo {
            min-height: 50px;
            border-radius: 10px;
            border: none;
            background-color: #3333FF;
            color: white;
        }
        .go-back-cupo-modal{
            cursor: pointer;
        }
    }
</style>
