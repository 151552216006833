import Swal from 'sweetalert2'

export const toastError = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    background: '#FF6464',
    color: '#fff',
    iconHtml: `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="times-circle-solid" d="M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm4.9,12.625a.484.484,0,0,1,0,.685l-1.6,1.593a.484.484,0,0,1-.685,0L18,20.258,15.375,22.9a.484.484,0,0,1-.685,0l-1.593-1.6a.484.484,0,0,1,0-.685L15.742,18,13.1,15.375a.484.484,0,0,1,0-.685l1.6-1.6a.484.484,0,0,1,.685,0L18,15.742,20.625,13.1a.484.484,0,0,1,.685,0l1.6,1.6a.484.484,0,0,1,0,.685L20.258,18Z" transform="translate(-8 -8)" fill="#fff"/>
        </svg>
    `,
    customClass: {
        icon: 'custom-swal-icon',
        title: 'custom-swal-title',
        popup: 'custom-swal-popup',
        container: 'custom-swal-container'
    },
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});