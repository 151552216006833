<template>
    <div class="btn-whatsapp">
        <div class="btn-whatsapp__alert">
            <a href="https://api.whatsapp.com/send?phone=573124605748&text=%C2%A1Hola!%20Quiero%20tener%20mas%20informaci%C3%B3n." target="_blank" class="alert__text">
                ¿Necesitas ayuda?
            </a>
            <span class="alert__close" @click="hiddenalert">
                &times;
            </span>
        </div>
        <div class="btn-whatsapp__figure">
            <a href="https://api.whatsapp.com/send?phone=573124605748&text=%C2%A1Hola!%20Quiero%20tener%20mas%20informaci%C3%B3n." target="_blank">
                <img src="@/assets/icons/icon-whatsapp_white.svg" alt="icon whatsapp">
            </a>
        </div>
    </div>
</template>
<script>
export default {
    methods: {   
        showalert() {
            const alert = document.querySelector('.btn-whatsapp__alert')
            alert.style.visibility = 'visible'
            alert.style.opacity = 1
            alert.style.bottom = '120%'
        },
        hiddenalert() {
            const alert = document.querySelector('.btn-whatsapp__alert')
            alert.style.visibility = 'hidden'
            alert.style.opacity = 0
            alert.style.bottom = '0%'
        }
    },
    mounted() {
        setTimeout(() => {
            this.showalert()
        }, 3000);
    }
}
</script>
<style lang="scss">
    .btn-whatsapp {
        position: fixed;
        z-index: 10;
        bottom: 2.5rem;
        right: 1rem;
        width: 50px;
        height: 50px;
        background-color: #00b66c;  
        border-radius: 50%; 
        &__alert {
            position: absolute;
            bottom: 0%;
            opacity: 0;
            visibility: hidden;
            transition: all .3s linear;
            right: 0;
            font-weight: 600;
            border-radius: 0.5rem 0.5rem 0 0;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            min-width: 200px;
            .alert__text {
                background-color: white;
                padding: .5rem 1rem;
                border-radius: 50px;
                border: 2px solid black;
                cursor: pointer;
                box-shadow: 3px 4px #00b66c;
                text-decoration: none;
                color: black !important;
            }
            .alert__close {
                background-color: black;
                color: rgb(233, 229, 229);
                border-radius: 50%;
                padding: 0.3rem 0.4rem;
                margin-left: 0.5rem;
                font-weight: bold;
                font-size: 1rem;
                line-height: .8;
                cursor: pointer;
            }
        }
        &__figure {
            position: absolute;
            top: 53%;
            left: 51%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            img {
                width: 28px;
                filter: brightness(100%);
            }
        }
        @media (max-width: 768px) {
            right: 0.5rem;
        }
    }
</style>