import Vue from 'vue'

export async function sendSoliCupo({ state }, payload) {
    state.errorSoliCupo = false;
    state.msgErrorSoliCupo = '';
    state.loadingSoliCupo = true;
    let data = new FormData();
    data.append('step', payload.step);
    data.append('nombres', payload.nombres);
    data.append('apellidos', payload.apellidos);
    data.append('documento', payload.documento);
    data.append('fecha_expedicion', payload.fecha_expedicion);
    if (payload.step == 2) {
        data.append('celular1', payload.celular1);
        data.append('correo1', payload.correo1);
        data.append('ciudad', payload.ciudad);
        data.append('departamento', payload.departamento);
    }
    await Vue.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/cupopersonal/registrarSolicitudExterna',
        data: data,
    })
        .then((response) => {
            if(response || state) console.log("Response success");
        }).catch((e) => {
            state.errorSoliCupo = true;
            state.msgErrorSoliCupo = Vue.getError422(e);
        }).finally(() => {
            state.loadingSoliCupo = false;
            console.log("End fetch soli");
        })
}