<template>
  <div id="app">
    <principal-layout/>
    <modal-cupo/>
    <button-whatsapp/>
  </div>
</template>
<script>
import ModalCupo from '@/components/ModalCupo.vue'
import PrincipalLayout from '@/components/layouts/PrincipalLayout.vue'
import { mapState } from 'vuex';
import ButtonWhatsapp from '@/components/ButtonWhatsapp.vue';
export default {
  components: { ModalCupo, PrincipalLayout, ButtonWhatsapp },
  computed: {
    ...mapState('globalState', ['tipoLayout'])
  }
}
</script>
<style lang="scss">
  #app {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
  }
</style>
