import Vue from 'vue'
Vue.mixin({
  methods: {
    obtenerLink(ruta) {
      const route = window.location.href
      const separator = route.includes('/index.html#') ? 'index.html#' : '#/'
      const baseRoute = route.split(separator)[0]
      const routeEnd = ruta.startsWith('/') ? ruta.substring(1) : ruta
      return baseRoute + routeEnd
    },
    getError422(e){
      let message = ""
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + " "
      }
      return message;
    },
  },
})