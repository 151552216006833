<template>
  <div class="container-menu">
    <router-link :to="{name: 'Home'}">
      <img src="@/assets/logo-quac-blanco.svg" alt="Logo Quac">
    </router-link>
    <div class="content-nav-items">
      <p @click="navTo('benefits')">Beneficios del crédito</p>
      <p @click="navTo('howfound')">¿Cómo funciona?</p>
      <p @click="navTo('ecommerce')">Comercios aliados</p>
      <p @click="navTo('downloadapp')">Descargar</p>
      <!-- <button class="ml-1 btn-quac-1">Pagar cuota</button> -->
    </div>
    <!-- <img class="btn-show-menu" src="@/assets/btn-show-menu.svg" /> -->
  </div>
</template>
<script>
export default {
  methods: {
    navTo(nav) {
      if(this.$route.name == 'Home') {
        var element = document.getElementById(nav)
        window.scrollTo(0, element.offsetTop)
      } else {
        this.$router.push({name: 'Home', params: {'navTo': nav}})
      }
    }
  },
  watch: {
    $route() {
      if(this.$route.params.navTo) {
        setTimeout(() => {
          this.navTo(this.$route.params.navTo);
        }, 100);
      }
    }
  },
}
</script>
<style lang="scss">
.container-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: absolute;
  z-index: 100;
  min-height: 80px;
  width: 100%;
  max-width: 1920px;
  .content-nav-items {
    display: flex;
    align-items: center;
    p {
      cursor: pointer;
      padding: 0 .7rem;
      text-decoration: none;
      color: white;
      margin-bottom: 0;
    }
  }
  .btn-show-menu{
    display: none;
    width: 36px;
    cursor: pointer
  }
  @media (max-width: 768px) {
    .content-nav-items {
      display: none;
    }
    .btn-show-menu{
      display: flex;
    }
  }
}
</style>