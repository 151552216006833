import Vue from 'vue'

import {
        LayoutPlugin, ButtonPlugin, LinkPlugin, AlertPlugin,
        NavPlugin, NavbarPlugin, SidebarPlugin, ModalPlugin,
        ImagePlugin, TabsPlugin, BFormCheckboxGroup,
        BCard, BCardText, PopoverPlugin, BIconChevronRight,
        BIconChevronLeft, BToast, ToastPlugin, VBHover, FormPlugin,
        InputGroupPlugin, BInputGroup, VBModal,
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(LayoutPlugin)
Vue.use(ButtonPlugin)
Vue.use(LinkPlugin)
Vue.use(AlertPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(SidebarPlugin)
Vue.use(ModalPlugin)
Vue.use(ImagePlugin)
Vue.use(TabsPlugin)
Vue.use(PopoverPlugin)
Vue.use(ToastPlugin)
Vue.use(FormPlugin)
Vue.use(InputGroupPlugin)
Vue.directive('b-hover', VBHover)
Vue.directive('b-modal', VBModal)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-card', BCard)
Vue.component('b-card-text', BCardText)
Vue.component('b-icon-chevron-right', BIconChevronRight)
Vue.component('b-icon-chevron-left', BIconChevronLeft)
Vue.component('b-toast', BToast)
